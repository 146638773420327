import {Route, Switch} from "react-router-dom";
import React from "react";
import ResearchPolicyGraphs from "./pgs";
import ResearchPANs from "./pans";
import ResearchFocus from "./focus";
import ResearchAloe from "./aloe";
import ResearchMAMe from "./mame";
import ResearchFluidC from "./fluidc";
import ResearchAtoms from "./atoms";
import ResearchLivers from "./livers";
import ResearchEmbryo from "./embryo";
import ResearchSuSy from "./susy";


function projectPageMappings() {
    // TODO: Create mappings for more project pages here
    return [<Route path="/project-page/pgs" component={ResearchPolicyGraphs} />,
            <Route path="/project-page/pans" component={ResearchPANs} />,
            <Route path="/project-page/aloe" component={ResearchAloe} />,
            <Route path="/project-page/mame" component={ResearchMAMe} />,
            <Route path="/project-page/fluidc" component={ResearchFluidC} />,
            <Route path="/project-page/focus" component={ResearchFocus} />,
            <Route path="/project-page/livers" component={ResearchLivers} />,
            <Route path="/project-page/atoms" component={ResearchAtoms} />,
            <Route path="/project-page/susy" component={ResearchSuSy} />,
            <Route path="/project-page/embryo" component={ResearchEmbryo} />];
};

export default projectPageMappings;
